<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          label="Select Item"
          single-line
          v-model="record.ItemCode"
          :item-text="record.ItemName"
          :item-value="record.ItemCode"
          :append-icon="'mdi-airballoon'"
          @click:append="getPriceListItems()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-autocomplete
          outlined
          label="Source Price"
          v-model="record.SrcPrice"
          :items="SrcPrc"
          item-text="text"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6">
        <v-autocomplete
          outlined
          item-text="CardName"
          item-value="CardCode"
          label="Business Partner"
          v-model="record.CardCode"
          :items="BusinessPartners"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" sm="6">
        <v-text-field
          outlined
          item-text="descript"
          item-value="id"
          label="Territory"
          v-model="record.LctCode"
          @click="openRegionModal"
        ></v-text-field>
      </v-col> -->
      <v-col cols="12" sm="6">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Active From"
              readonly
              v-on="on"
              v-model="record.ValidFrom"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="record.ValidFrom"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              label="End Date"
              readonly
              v-on="on"
              outlined
              v-model="record.ValidTo"
            ></v-text-field>
          </template>
          <v-date-picker v-model="record.ValidTo"></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          label="Active*"
          type="Active"
          v-model="record.Valid"
          required
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-btn color="primary" @click="sendData"> Save </v-btn>

    <!-- start of territories dialog -->
    <v-dialog v-model="regionDialog" width="650px">
      <v-card>
        <v-toolbar color="accent">
          <v-toolbar-title>Select Territory</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="regionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-treeview
                color="accent"
                :activatable="trueValue"
                :hoverable="trueValue"
                :selection-type="selectionType"
                open-all
                return-object
                item-text="descript"
                item-key="id"
                :items="territories"
                item-children="children_recursive"
                :search="search"
                selectable
              >
                <template v-slot:label="{ item }">
                  <div @click="selectRegion(item)">
                    <span style="font-weight: bold">{{ item.descript }}</span>
                  </div>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- one item selection modal -->
    <v-dialog v-model="itemDialog" width="950">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="itemDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Select using Item Code or Item Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  dense
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="itemMasterHeaders"
                  :items="itemMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  :options.sync="options"
                  :server-items-length="totalItems"
                  class="elevation-1"
                  :server-search="true"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->
  </v-container>
</template>
<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    itemDialog: false,
    itemsPerPage: 100,
    selectedItems: [],
    selectedItem: {},
    itemMasterHeaders: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Item Description", value: "ItemName" },
      { text: "In Stock", value: "OnHand" },
    ],
    tableRows: [{ text: "ItemCode", value: "" }],
    saleItemsData: [],
    itemMasterData: [],
    singleSelect: true,
    searchInput: "",
    searchItem: "",
    selectionType: "leaf",
    trueValue: true,
    regionDialog: false,
    priceList: [],
    search: "",
    Items: [],
    Locations: [],
    territories: [],
    BusinessPartners: [],
    record: {},
    SrcPrc: [
      { value: 0, text: "Price - Pri. Curr." },
      { value: 1, text: "Price - Add. Curr. 1" },
      { value: 3, text: "Price - Add. Curr. 2" },
    ],
    dialog: false,
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
  }),
  watch: {
    options: {
      handler() {
        this.getPriceListItems();
      },
      deep: true,
    },
    searchItem() {
      this.options.page = 1;
      if (this.searchItem.length >= 2) {
        this.getPriceListItems();
      }
      if (this.searchItem.length == 0) {
        this.getPriceListItems();
      }
    },
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      console.log(this.record);
    },
    clickedItem($event) {
      this.selectedItem = $event.item;
      this.record.ItemCode = this.selectedItem.ItemCode;
      this.record.ItemName = this.selectedItem.ItemName;
      console.log(this.selectedItem);
      // end of default values
      this.itemDialog = false;
    },
    getPriceList() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/price_lists`)
        .then((res) => {
          self.priceList = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getPriceListItems() {
      this.itemDialog = true;
      const self = this;
      this.loading = true;
      let url = `/price_lists/${this.$route.params.ListNum}/items?page=${this.options.page}&per_page=${this.options.itemsPerPage}`;

      if (this.searchItem && this.searchItem != undefined) {
        url += `&search=${this.searchItem}`;
      }

      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.itemMasterData = res.ResponseData.data;
          self.options.page = res.ResponseData.current_page;
          self.options.itemsPerPage = res.ResponseData.per_page;
          self.totalItems = res.ResponseData.total;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    getLocations() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `locations`)
        .then((res) => {
          self.Locations = res.ResponseData;

          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    // SaveItemDiscnt() {
    //   const url = `/period-and-vol-discounts`;
    //   const self = this;
    //   this.record.ListNum = this.$route.params.id;
    //   const data = this.record;
    //   console.log(data);
    //   self.$store
    //     .dispatch("post", { url, data })
    //     .then(() => {
    //       this.dialog = false;
    //       this.record = {};
    //       this.getItems();
    //     })
    //     .catch((err) => {
    //       console.log(err, "err");
    //       this.dialog = false;
    //       this.$refs.snackbar.show(err, "red");
    //     });
    // },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
    getBpartners() {
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          let Partners = {
            CardCode: "-1",
            CardName: "ALL",
          };

          this.BusinessPartners = res.ResponseData;
          this.BusinessPartners.push(Partners);
          this.BusinessPartners.sort((a, b) => a.CardCode - b.CardCode);
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    selectRegion(data) {
      this.territory = data;
      console.log(data);
      this.record.LctCode = data.id;
      this.regionDialog = false;
    },
    getTerritories() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/territories`)
        .then((res) => {
          self.territories = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    openRegionModal() {
      this.regionDialog = true;
    },
  },
  created() {
    this.getLocations();
    this.getBpartners();
    this.getPriceList();
    this.getTerritories();
  },
};
</script>
