<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Add Item</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text @click="goBack">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor
          :initial="dataItem"
          :docCreate="docCreate"
          @data="save"
        ></editor>
        <!-- end -->

        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    docCreate: true,
  }),
  methods: {
    save(data) {
      data.ListNum = this.$route.params.ListNum;
      localStorage.setItem("PickedPriceList", data.ListNum);
      const url = `/period-and-vol-discounts`;
      const self = this;
      self.$store
        .dispatch("post", { url, data })
        .then(() => {
          this.dialog = false;
          this.record = {};
          this.goBack();
        })
        .catch((err) => {
          console.log(err, "err");
          this.dialog = false;
          this.$refs.snackbar.show(err, "red");
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
